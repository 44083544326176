import React from 'react'
import { Layout } from '@components/layout'
import { graphql } from 'gatsby'
import { SmallContainerFlex } from '@components/container/container'
import { useQueryParam } from 'gatsby-query-params'
import { SpringFadeUp } from '@utils/animations'

type Props = {
  data: any
  pageContext: any
}

const BookTourPage: React.FC<Props> = props => {
  const { meta, header, seo, main, footer } = props.data
  const formId = useQueryParam('formId', 'mV0ePl')
  return (
    <>
      <Layout
        seo={seo}
        header={header}
        footer={footer}
        // theme={theme}
        activeMeta={meta}
        loadScriptUrl="https://tally.so/widgets/embed.js"
      >
        <SpringFadeUp delay={300}>
          <SmallContainerFlex className="book-form-tally">
            <iframe
              data-tally-src={`https://tally.so/embed/${formId}?alignLeft=1&hideTitle=0&transparentBackground=1&dynamicHeight=1`}
              loading="lazy"
              width="100%"
              title="The Camera Obscura Boat Tour"
            ></iframe>
          </SmallContainerFlex>
        </SpringFadeUp>
      </Layout>
    </>
  )
}

export default BookTourPage

export const pageQuery = graphql`
  query BookTourPageQuery1($locale: String) {
    meta: prismicHomepage(lang: { eq: $locale }) {
      lang
      type
      alternate_languages {
        uid
        lang
      }
    }
    seo: prismicHomepage(lang: { eq: $locale }) {
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url(imgixParams: { width: 1200, height: 630 })
        }
      }
    }

    header: prismicMenu(lang: { eq: $locale }) {
      ...header
    }

    footer: prismicFooter(lang: { eq: $locale }) {
      ...footer
    }
  }
`
